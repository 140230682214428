<template>
  <div class="row mt-3">
    <div class="col-12 mb-3">
      <h4>
        Harap Lampirkan Dokumen
        <small class="text-danger">(*) wajib diupload</small>
      </h4>
      <ol>
        <li>
          <small><b>File tidak lebih dari 2 mb</b></small>
        </li>
        <li>
          <small><b>File PDF/JPG</b></small>
        </li>
      </ol>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label>Ijazah</label>
        <input
          type="file"
          class="form-control"
          id="ijazah"
          @change="addIjazah"
          accept="image/jpeg,application/pdf"
        />
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label>Curriculum Vitae (CV)<small class="text-danger">*</small></label>
        <input
          type="file"
          class="form-control"
          id="cv"
          @change="addCv"
          accept="image/jpeg,application/pdf"
          :class="[{ 'is-invalid': errorFor('cv') }]"
        />
        <small class="text-danger" v-if="errorFor('cv')">{{
          errorFor("cv")
        }}</small>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label
          >Kartu Tanda Penduduk (KTP)<small class="text-danger">*</small></label
        >
        <input
          type="file"
          class="form-control"
          id="ktp"
          @change="addKtp"
          accept="image/jpeg,application/pdf"
          :class="[{ 'is-invalid': errorFor('ktp') }]"
        />
        <small class="text-danger" v-if="errorFor('ktp')">{{
          errorFor("ktp")
        }}</small>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label>Kartu Keluarga (KK)<small class="text-danger">*</small></label>
        <input
          type="file"
          class="form-control"
          id="kk"
          @change="addKk"
          accept="image/jpeg,application/pdf"
          :class="[{ 'is-invalid': errorFor('kk') }]"
        />
        <small class="text-danger" v-if="errorFor('kk')">{{
          errorFor("kk")
        }}</small>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label>Surat Izin Mengemudi (SIM)</label>
        <input
          type="file"
          class="form-control"
          id="sim"
          @change="addSim"
          accept="image/jpeg,application/pdf"
        />
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label>Surat Nikah</label>
        <input
          type="file"
          class="form-control"
          id="sn"
          @change="addSuratNikah"
          accept="image/jpeg,application/pdf"
        />
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label>Akta Kelahiran Anak</label>
        <input
          type="file"
          class="form-control"
          id="aka"
          @change="addAktaKelahiran"
          accept="image/jpeg,application/pdf"
        />
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label>Sertifikat yang pernah diikuti</label>
        <input
          type="file"
          class="form-control"
          id="sertifikat"
          @change="addSertifikat"
          accept="image/jpeg,application/pdf"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ijazah: null,
      cv: null,
      ktp: null,
      kk: null,
      sim: null,
      suratNikah: null,
      aktaKelahiran: null,
      sertifikat: null,
    };
  },
  props: ["errors"],
  methods: {
    alertValid(e, name) {
      if (e.target.files[0].size > 2097152) {
        alert("File lebih besar dari 2 MB");
        document.getElementById(name).value = "";
        return null;
      } else {
        return e.target.files[0];
      }
    },
    addIjazah(e) {
      this.ijazah = this.alertValid(e, "ijazah");
    },
    addCv(e) {
      this.cv = this.alertValid(e, "cv");
    },
    addKtp(e) {
      this.ktp = this.alertValid(e, "ktp");
    },
    addKk(e) {
      this.kk = this.alertValid(e, "kk");
    },
    addSim(e) {
      this.sim = this.alertValid(e, "sim");
    },
    addSuratNikah(e) {
      this.suratNikah = this.alertValid(e, "sn");
    },
    addAktaKelahiran(e) {
      this.aktaKelahiran = this.alertValid(e, "aka");
    },
    addSertifikat(e) {
      this.sertifikat = this.alertValid(e, "sertifikat");
    },
    errorFor(field) {
      if (this.errors != null) {
        if (typeof this.errors[field] == "object") {
          return this.errors[field][0];
        }
      }
      return null;
    },
    resetData() {
      Object.keys(this.$data).forEach((key) => {
        this.$data[key] = null;
      });
    },
  },
};
</script>
