import VueRouter from 'vue-router';
import Job from './pages/Job.vue';
import ErrorPage from './pages/Error.vue';
import Welcome from './pages/Welcome.vue';
import Form from './pages/Form.vue';
import Success from './pages/Success.vue';
const routes = [
    {
        path: "/",
        component: Welcome,
        name: "welcome"
    },
    {
        path: "/job/:id",
        component: Job,
        name: "job"
    },
    {
        path: "/not-found",
        component: ErrorPage,
        name: "error"
    },
    {
        path: "/form",
        component: Form,
        name: "form"
    },
    {
        path: "/success",
        component: Success,
        name: "success"
    },
];

const router = new VueRouter({
    routes,
    mode: "history"
});

export default router;