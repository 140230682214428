<template>
    <div class="row mt-3">
         <div class="col-12 mb-3">
            <h4>C. KURSUS/SEMINAR/PELATIHAN/LOKAKARYA YANG PERNAH DIIKUTI</h4>
        </div>
        <div class="col-12 mb-4">
            <button class="btn btn-primary" @click.prevent="addCourse">Tambah Kursus/Seminar</button>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label>Judul</label>
                <div class="row">
                    <div class="col-12 mt-3" v-for="(course, i) in courses" :key="i">
                        <input type="text" class="form-control" v-model="courses[i].title">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label>Penyelenggara</label>
                <div class="row">
                    <div class="col-12 mt-3" v-for="(course, i) in courses" :key="i">
                        <input type="text" class="form-control" v-model="courses[i].organizedBy">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label>Tanggal Kursus</label>
                <div class="row">
                    <div class="col-12 mt-3" v-for="(course, i) in courses" :key="i">
                        <input type="date" class="form-control" v-model="courses[i].date">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label>Lama Kursus (Jam, Hari)</label>
                <div class="row">
                    <div class="col-12 mt-3" v-for="(course, i) in courses" :key="i">
                        <input type="text" class="form-control" v-model="courses[i].interval">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            courses: [{
                title: null,
                organizedBy: null,
                date: null,
                interval: null
            }]
        }
    },
    methods: {
        addCourse() {
            this.courses.push({
                title: null,
                organizedBy: null,
                date: null,
                interval: null
            });
        },
        resetData() {
             this.courses = [{
                title: null,
                organizedBy: null,
                date: null,
                interval: null
            }];
        }
    }
}
</script>