<template>
  <div class="main-wrapper" :class="[{'success-wrapper': recentPage == 'success'}]">
    <div class="page-wrapper ml-0" v-if="recentPage != 'success'">
      <div class="row">
        <div class="col-12 text-center">
          <img src="/logo.png" @click.prevent="toMainPath">
        </div>
      </div>
      <router-view></router-view>
    </div>
    <Success v-else />
      <!-- /Page Wrapper -->
  </div>
</template>

<script>
import Success from './pages/Success';

export default {
  data() {
    return {
      recentPage: this.$route.name
    }
  },
  components: {
    Success
  },
  methods: {
    toMainPath() {
      this.$router.push('/');
    }
  }
}
</script>

<style scoped>
img {
  cursor: pointer;
  max-width: 400px;
}

.success-wrapper {
  background-color:  rgba(255, 155, 68, 0.2);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
