<template>
    <div class="row mt-3">
        <div class="col-12 mb-3">
            <h4>F. RIWAYAT PENYAKIT PRIBADI/ISTRI/SUAMI/ANAK</h4>
        </div>
        <div class="col-12 mb-4">
            <button class="btn btn-primary" @click.prevent="addDesease">Tambah Riwayat Penyakit</button>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label>Nama</label>
                <div class="row">
                    <div class="col-12 mt-3" v-for="(desease, i) in deseases" :key="i">
                        <input type="text" class="form-control" v-model="deseases[i].name">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label>Jenis Penyakit</label>
                <div class="row">
                    <div class="col-12 mt-3" v-for="(desease, i) in deseases" :key="i">
                        <input type="text" class="form-control" v-model="deseases[i].type">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <div class="form-group">
                <label>Hubungan</label>
                <div class="row">
                    <div class="col-12 mt-3" v-for="(desease, i) in deseases" :key="i">
                        <select class="form-control" v-model="deseases[i].relation">
                            <option :value="null" selected disabled>--Pilihan--</option>
                            <option value="Pribadi">Pribadi</option>
                            <option value="Istri">Istri</option>
                            <option value="Suami">Suami</option>
                            <option value="Anak">Anak</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <div class="form-group">
                <label>Tahun dirawat</label>
                <div class="row">
                    <div class="col-12 mt-3" v-for="(desease, i) in deseases" :key="i">
                        <input type="number" class="form-control" v-model="deseases[i].year">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <div class="form-group">
                <label>Tempat</label>
                <div class="row">
                    <div class="col-12 mt-3" v-for="(desease, i) in deseases" :key="i">
                        <input type="text" class="form-control" v-model="deseases[i].places">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            deseases: [{
                name: null,
                type: null,
                relation: null,
                year: null,
                place: null
            }]
        }
    },
    methods: {
        addDesease() {
            this.deseases.push({
                name: null,
                type: null,
                relation: null,
                year: null,
                place: null
            });
        },
        resetData() {
            this.deseases = [{
                name: null,
                type: null,
                relation: null,
                year: null,
                place: null
            }];
        }
    }
}
</script>