<template>
     <div class="row mt-3">
        <div class="col-12 mb-3">
            <h4>KETERANGAN LAIN-LAIN :</h4>
            <p>Tuliskan di ruang ini kalau ada keterangan lain yang perlu dan relevant untuk dicantumkan.</p>
            <div class="form-group">
                <textarea class="form-control" v-model="information"></textarea>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            information: null
        }
    },
    methods: {
        resetData() {
            this.information = null;
        }
    }
}
</script>