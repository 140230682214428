<template>
    <div class="row mt-3">
        <div class="col-12 mb-3">
            <h4>G. LAIN-LAIN <small class="text-danger">(*) wajib diisi</small></h4>
        </div>
        <div class="col-md-6 col-sm-12">
            <div class="form-group">
                <label>Tuliskan kegiatan-kegiatan Saudara untuk mengisi waktu luang. <small class="text-danger">*</small></label>
                <textarea class="form-control" v-model="freeTime" :class="[{'is-invalid': errorFor('others.freeTime')}]"></textarea>
                <small class="text-danger" v-if="errorFor('others.freeTime')">{{ errorFor('others.freeTime') }}</small>
            </div>
        </div>
        <div class="col-md-6 col-sm-12">
            <div class="form-group">
                <label>Jelaskan kegemaran atau hobby <small class="text-danger">*</small></label>
                <textarea class="form-control" v-model="hobby" :class="[{'is-invalid': errorFor('others.hobby')}]"></textarea>
                <small class="text-danger" v-if="errorFor('others.freeTime')">{{ errorFor('others.freeTime') }}</small>
            </div>
        </div>
        <div class="col-md-6 col-sm-12">
            <div class="form-group">
                <label>Sebutkan tugas-tugas yang Anda ketahui mengenai jabatan yang Anda lamar? <small class="text-danger">*</small></label>
                <textarea class="form-control" v-model="jobDesc" :class="[{'is-invalid': errorFor('others.jobDesc')}]"></textarea>
                <small class="text-danger" v-if="errorFor('others.jobDesc')">{{ errorFor('others.jobDesc') }}</small>
            </div>
        </div>
        <div class="col-md-6 col-sm-12">
            <div class="form-group">
                <label>Prestasi apa yang pernah anda capai dalam hidup anda ? <small>(terutama saat bekerja)</small></label>
                <textarea class="form-control" v-model="achievement"></textarea>
            </div>
        </div>
        <div class="col-md-6 col-sm-12">
            <div class="form-group">
                <label>Motivasi anda melamar di PT. Bintoro Group ? <small class="text-danger">*</small></label>
                <textarea class="form-control" v-model="motivation" :class="[{'is-invalid': errorFor('others.motivation')}]"></textarea>
                <small class="text-danger" v-if="errorFor('others.motivation')">{{ errorFor('others.motivation') }}</small>
            </div>
        </div>
        <div class="col-md-6 col-sm-12">
            <div class="form-group">
                <label>Sebutkan 3 kekurangan Anda dan 3 kelebihan Anda. <small class="text-danger">*</small></label>
                <textarea class="form-control" v-model="plusMinus" :class="[{'is-invalid': errorFor('others.plusMinus')}]"></textarea>
                <small class="text-danger" v-if="errorFor('others.plusMinus')">{{ errorFor('others.plusMinus') }}</small>
            </div>
        </div>
        <div class="col-md-6 col-sm-12">
            <div class="form-group">
                <label>Gaji yang Anda harapkan ? <small class="text-danger">*</small></label>
                <textarea class="form-control" v-model="salary" :class="[{'is-invalid': errorFor('others.salary')}]"></textarea>
                <small class="text-danger" v-if="errorFor('others.salary')">{{ errorFor('others.salary') }}</small>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            freeTime: null,
            hobby: null,
            achievement: null,
            motivation: null,
            plusMinus: null,
            salary: null,
            jobDesc: null
        }
    },
    props: ['errors'],
    methods: {
        errorFor(field) {
           if(this.errors != null) {
                if(typeof this.errors[field] == "object") {
                    return this.errors[field][0];
                }
            }
            return null;
        },
        resetData() {
            Object.keys(this.$data).forEach((key) => {
                this.$data[key] = null;
            })
        }
    }
}
</script>