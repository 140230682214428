<template>
    <div class="row mt-3">
        <div class="col-12 mb-3">
            <h4>H. REFERENSI <small class="text-danger">(*) wajib diisi</small></h4>
            <p>Untuk mengetahui lebih lanjut mengenai diri Saudara, sebutkan minimal nama 2 (dua) orang referensi Saudara yang dapat kami hubungi. Bukan dari anggota keluarga atau family Saudara.</p>
        </div>
        <div class="col-12 mb-4">
            <button class="btn btn-primary" @click.prevent="addReference">Tambah Referensi</button>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>Nama <small class="text-danger">*</small></label>
                <div class="row">
                    <div class="col-12 mt-3" v-for="(reference, i) in references" :key="i">
                        <input type="text" class="form-control" v-model="references[i].name" :class="[{'is-invalid': errorFor(`references.references.${i}.name`)}]">
                        <small class="text-danger" v-if="errorFor(`references.references.${i}.name`)">{{ errorFor(`references.references.${i}.name`) }}</small>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>Jabatan & Perusahaan <small class="text-danger">*</small></label>
                <div class="row">
                    <div class="col-12 mt-3" v-for="(reference, i) in references" :key="i">
                        <input type="text" class="form-control" v-model="references[i].positionCompany" :class="[{'is-invalid': errorFor(`references.references.${i}.positionCompany`)}]">
                        <small class="text-danger" v-if="errorFor(`references.references.${i}.positionCompany`)">{{ errorFor(`references.references.${i}.positionCompany`) }}</small>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>Address & Nomor Telepon <small class="text-danger">*</small></label>
                <div class="row">
                    <div class="col-12 mt-3" v-for="(reference, i) in references" :key="i">
                        <input type="text" class="form-control" v-model="references[i].addressPhone" :class="[{'is-invalid': errorFor(`references.references.${i}.addressPhone`)}]">
                        <small class="text-danger" v-if="errorFor(`references.references.${i}.addressPhone`)">{{ errorFor(`references.references.${i}.addressPhone`) }}</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            references: [
                {
                    name: null,
                    positionCompany: null,
                    addressPhone: null
                },
                {
                    name: null,
                    positionCompany: null,
                    addressPhone: null
                }
            ]
        }
    },
    props: ['errors'],
    methods: {
        addReference() {
            this.references.push({
                name: null,
                positionCompany: null,
                addressPhone: null
            });
        },
         errorFor(field) {
            if(this.errors != null) {
                if(typeof this.errors[field] == "object") {
                    return this.errors[field][0];
                }
            }
            return null;
        },
        resetData() {
             this.references = [
                {
                    name: null,
                    positionCompany: null,
                    addressPhone: null
                },
                {
                    name: null,
                    positionCompany: null,
                    addressPhone: null
                }
            ];
        }
    }
}
</script>