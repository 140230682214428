<template>
  <div class="row mt-3">
    <div class="col-12 mb-3">
      <h4>
        I. PERNYATAAN PELAMAR
      </h4>
      <p>
        "Dengan ini saya menerangkan dan menyatakan bahwa semua keterangan yang
        saya berikan di atas adalah benar adanya. Apabila ternyata keterangan
        tersebut tidak benar atau dipalsukan, maka saya bersedia diberhentikan
        dari pekerjaan saya sesuai dengan peraturan yang berlaku".
      </p>
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          value="setuju"
          v-model="agreement"
          :class="[{ 'is-invalid': errorFor('statement.agreement') }]"
        />
        <label class="form-check-label" for="defaultCheck1">
          Saya Setuju <small class="text-danger">(*) wajib diisi</small></label
        ><br />
        <small class="text-danger" v-if="errorFor('others.salary')">{{
          errorFor("statement.agreement")
        }}</small>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      agreement: null,
    };
  },
  props: ["errors"],
  methods: {
    errorFor(field) {
      if (this.errors != null) {
        if (typeof this.errors[field] == "object") {
          return this.errors[field][0];
        }
      }
      return null;
    },
    resetData() {
      this.agreement = null;
    },
  },
};
</script>
