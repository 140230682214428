<template>
    <div class="row">
        <div class="col-12 mb-3">
            <h4>A. PERSONAL DATA <small class="text-danger">(*) wajib diisi</small></h4>
        </div>
        <div class="col-md-6 col-sm-12">
            <div class="form-group">
                <label>Nama <small class="text-danger">*</small></label>
                <input type="text" class="form-control" v-model="name" :class="[{'is-invalid': errorFor('personal.name')}]">
                <small class="text-danger" v-if="errorFor('personal.name')">{{ errorFor('personal.name') }}</small>
            </div>
        </div>
        <div class="col-md-6 col-sm-12">
            <div class="form-group">
                <label>Jabatan Yang Dilamar</label>
                <input type="text" :value="jobName" disabled readonly class="form-control">
            </div>
        </div>
        <div class="col-md-6 col-sm-12">
            <div class="form-group">
                <label>Jenis Kelamin <small class="text-danger">*</small></label>
                <select class="form-control" v-model="sex" :class="[{'is-invalid': errorFor('personal.sex')}]">
                    <option :value="null" selected disabled>--Pilihan--</option>
                    <option>Laki-Laki</option>
                    <option>Perempuan</option>
                </select>
                <small class="text-danger" v-if="errorFor('personal.sex')">{{ errorFor('personal.sex') }}</small>
            </div>
        </div>
        <div class="col-md-6 col-sm-12">
            <div class="form-group">
                <label>Lokasi Penempatan</label>
                <select 
                    class="form-control" 
                    v-model="site_city_id" 
                    :class="[{'is-invalid': errorFor('personal.site_city_id')}]"
                    disabled
                >
                    <option 
                        v-for="loc in location" 
                        :key="loc.id"
                        :value="loc.id"
                    >
                        {{ loc.site }}
                    </option>
                </select>
                <small class="text-danger" v-if="errorFor('personal.site_city_id')">{{ errorFor('personal.site_city_id') }}</small>
            </div>
        </div>
        <div class="col-md-6 col-sm-12">
            <div class="form-group">
                <label>Tempat & Tanggal Lahir <small class="text-danger">*</small></label>
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <input type="text" placeholder="Tempat" class="form-control" v-model="birthPlace" :class="[{'is-invalid': errorFor('personal.birthPlace')}]">
                        <small class="text-danger" v-if="errorFor('personal.birthPlace')">{{ errorFor('personal.birthPlace') }}</small>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <input type="date" placeholder="Tanggal Lahir" class="form-control" v-model="birth" :class="[{'is-invalid': errorFor('personal.birth')}]">
                        <small class="text-danger" v-if="errorFor('personal.birth')">{{ errorFor('personal.birth') }}</small>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-sm-12">
            <div class="form-group">
                <label>No. KTP <small class="text-danger">*</small></label>
                <input type="text" class="form-control" v-model="ktp" :class="[{'is-invalid': errorFor('personal.ktp')}]">
                <small class="text-danger" v-if="errorFor('personal.name')">{{ errorFor('personal.ktp') }}</small>
            </div>
        </div>
        <div class="col-md-6 col-sm-12">
            <div class="form-group">
                <label>Alamat Rumah <small class="text-danger">*</small></label>
                <textarea class="form-control" v-model="address" :class="[{'is-invalid': errorFor('personal.address')}]"></textarea>
                <small class="text-danger" v-if="errorFor('personal.name')">{{ errorFor('personal.address') }}</small>
            </div>
        </div>
        <div class="col-md-6 col-sm-12">
            <div class="form-group">
                <label>Telp No. <small class="text-danger">*</small></label>
                <input type="number" class="form-control" v-model="phone" :class="[{'is-invalid': errorFor('personal.phone')}]">
                <small class="text-danger" v-if="errorFor('personal.name')">{{ errorFor('personal.phone') }}</small>
            </div>
        </div>
        <div class="col-md-6 col-sm-12">
            <div class="form-group">
                <label>Nama dan alamat keluarga terdekat apabila ada emergency. <small class="text-danger">*</small></label>
                <textarea class="form-control" v-model="emergencyAddress" :class="[{'is-invalid': errorFor('personal.emergencyAddress')}]"></textarea>
                <small class="text-danger" v-if="errorFor('personal.name')">{{ errorFor('personal.emergencyAddress') }}</small>
            </div>
        </div>
        <div class="col-md-6 col-sm-12">
            <div class="form-group">
                <label>No. telp keluarga terdekat apabila ada emergency. <small class="text-danger">*</small></label>
                <input type="number" class="form-control" v-model="emergencyPhone" :class="[{'is-invalid': errorFor('personal.emergencyPhone')}]">
                <small class="text-danger" v-if="errorFor('personal.name')">{{ errorFor('personal.emergencyPhone') }}</small>
            </div>
        </div>
        <div class="col-md-6 col-sm-12">
            <div class="form-group">
                <label>No. SIM C</label>
                <input type="text" class="form-control" v-model="simC">
            </div>
        </div>
        <div class="col-md-6 col-sm-12">
            <div class="form-group">
                <label>No. SIM A</label>
                <input type="text" class="form-control" v-model="simA">
            </div>
        </div>
        <div class="col-md-6 col-sm-12">
            <div class="form-group">
                <label>No. NPWP</label>
                <input type="text" class="form-control" v-model="npwp">
            </div>
        </div>
        <div class="col-md-6 col-sm-12">
            <div class="form-group">
                <label>Email <small class="text-danger">*</small></label>
                <input type="text" class="form-control" v-model="email" :class="[{'is-invalid': errorFor('personal.email')}]">
                <small class="text-danger" v-if="errorFor('personal.name')">{{ errorFor('personal.email') }}</small>
            </div>
        </div>
        <div class="col-md-6 col-sm-12">
            <div class="form-group">
                <label>Daftar Anggota Keluarga</label>
                <div class="row">
                    <div class="col-12">
                        <input type="text" placeholder="Istri/Suami" class="form-control" v-model="wifeHusband">
                    </div>
                    <div class="col-12 mt-3">
                        <button class="btn btn-primary" @click.prevent="addChild">Tambah Data Anak</button>
                    </div>
                    <div class="col-12 mt-3" v-for="(child, i) in childs" :key="i">
                        <input type="text" class="form-control" placeholder="Nama Anak" v-model="childs[i]">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';

export default {
    data() {
        return {
            name: null,
            childs: [],
            sex: null,
            birthPlace: null,
            birth: null,
            ktp: null,
            address: null,
            phone: null,
            emergencyAddress: null,
            emergencyPhone: null,
            simC: null,
            simA: null,
            npwp: null,
            email: null,
            wifeHusband: null,
            location: [],
            site_city_id: null,
        }
    },
    props: ['errors'],
    computed: {
        ...mapState({
            jobName: state => state.job.name,
            apiUrl: "apiUrl"
        })
    },
    async mounted() {
        await this.getLocation();
        this.site_city_id = this.$route.query.loc;
    },
    methods: {
        async getLocation() {
            const { data } = (await axios.get(`${this.apiUrl}/api/site-city-lamar`)).data.results ?? [];

            this.location = data;
        },
        addChild() {
            this.childs.push('');
        },
        errorFor(field) {
            if(this.errors != null) {
                if(typeof this.errors[field] == "object") {
                    return this.errors[field][0];
                }
            }
            return null;
        },
        resetData() {
            Object.keys(this.$data).forEach((key) => {
                if(key == 'childs') {
                    this.$data[key] = [];
                } else {
                    this.$data[key] = null;
                }
            })
        }
    }

}
</script>