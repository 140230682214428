<template>
    <div class="text-center pt-5">
        <h1>Mohon maaf, lowongan pekerjaan ini belum tersedia.</h1>
    </div>
</template>

<script>
export default {
    
}
</script>