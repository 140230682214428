<template>
     <div class="content container-fluid">
        <!-- Page Header -->
        <div class="page-header">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <h2 class="page-title">Job Details</h2>
                </div>
            </div>
        </div>
        <!-- /Page Header -->
        
        <div class="row">
            <div class="col-md-8">
                <div class="job-info job-widget shadow-sm">
                    <h3 class="job-title">
                        <span v-if="job">{{ job.name }}</span>
                    </h3>
                    <span class="job-dept" v-if="job">{{ job.department }}</span>
                    <ul class="job-post-det" v-if="job">
                        <li><i class="fa fa-calendar"></i> Post Date: <span class="text-blue">{{ job.start_date }}</span></li>
                        <li><i class="fa fa-calendar"></i> Last Date: <span class="text-blue">{{ job.expire_date }}</span></li>
                        <!-- <li><i class="fa fa-user-o"></i> Applications: <span class="text-blue">4</span></li> -->
                    </ul>
                </div>
                <div class="job-content job-widget shadow-sm">
                    <div class="job-desc-title"><h4>Job Description</h4></div>
                    <div class="job-description" v-if="job">
                        <p v-html="job.description"></p>
                    </div>
                </div>
                <div class="job-content job-widget shadow-sm">
                    <div class="job-desc-title"><h4>Job Requirement</h4></div>
                    <div class="job-description" v-if="job">
                        <p v-html="job.requirement"></p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="job-det-info job-widget shadow-sm">
                    
                    <div class="info-list">
                        <span><i class="fa fa-bar-chart"></i></span>
                        <h5>Job Type</h5>
                        <p v-if="job"> {{ job.job_type }}</p>
                    </div>
                    <div class="info-list">
                        <span><i class="fa fa-money"></i></span>
                        <h5>Salary</h5>
                        <p v-if="job">Rp {{ job.salary_from | formatRupiah }} - Rp {{ job.salary_to | formatRupiah }}</p>
                    </div>
                    <div class="info-list">
                        <span><i class="fa fa-suitcase"></i></span>
                        <h5>Experience</h5>
                        <p v-if="job">{{ job.experience }}</p>
                    </div>
                    <div class="info-list">
                        <span><i class="fa fa-ticket"></i></span>
                        <h5>Vacancy</h5>
                        <p v-if="job">
                            <span v-if="job.vacancy">{{ job.vacancy }}</span>
                            <span v-else>-</span>
                        </p>
                    </div>
                    <div class="info-list">
                        <span><i class="fa fa-map-signs"></i></span>
                        <h5>Location</h5>
                        <!-- <p v-if="job">{{ job.location }}</p> -->
                        <select v-model="location" class="form-control my-3">
                            <option :value="null">Choose Location</option>
                            <option 
                                v-for="loc in job.location" 
                                :key="loc.id"
                                :value="loc.id" 
                            >
                                {{ loc.site }}
                            </option>
                        </select>
                    </div>
                    <!-- <div class="info-list">
                        <p class="text-truncate"> 818-978-7102
                        <br> <a href="mailto:danielporter@example.com" title="danielporter@example.com">danielporter@example.com</a>
                        <br> <a href="https://www.example.com" target="_blank" title="https://www.example.com">https://www.example.com</a>
                        </p>
                    </div>
                    <div class="info-list text-center">
                        <a class="app-ends" href="#">Application ends in 2d 7h 6m</a>
                    </div> -->
                    <button class="btn job-btn" disabled v-if="!location">Lamar</button>
                    <button class="btn job-btn" @click.prevent="toForm" v-else>Lamar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';

export default {
    data:() => ({
        job: null,
        location: null,
    }),
    created() {
        this.jobDetail();
    },
    methods: {
        async jobDetail() {
            try {
                const selectedJob = await axios.get(`${this.apiUrl}/api/jobs/lamar/${this.$route.params.id}`);
                this.job = selectedJob.data;
            } catch(error) {
                this.$router.push({ name: 'error' });
            }
        },
        toForm() {
            this.$store.dispatch('setJob', this.job);
            this.$router.push({ name: 'form', query: { loc: this.location } });
        }
    },
    computed: {
        ...mapState({
            apiUrl: "apiUrl"
        })
    },
    filters: {
        formatRupiah(num) {
            return new Intl.NumberFormat(['ban', 'id']).format(num);
        }
    }
}
</script>

<style scoped>
.job-btn {
    width: 100%;
}
</style>