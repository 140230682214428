<template>
    <div class="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3 card-job" @click="this.jobDetail">
        <div class="profile-widget">
            <div class="profile-img">
                <a href="#" @click.prevent class="avatar"><img :src="logo"></a>
            </div>
            <h4 class="user-name m-t-10 mb-0 text-ellipsis"><a href="#" @click.prevent>{{ name }}</a></h4>
            <div class="small text-muted">{{ jobtype }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['id', 'name', 'jobtype', 'logo'],
    methods: {
        jobDetail() {
            this.$emit('jobdetail', this.id);
        }
    }
}
</script>

<style scoped>
.card-job {
    cursor: pointer;
}

.avatar {
    background: #fff;
    border: 2px solid #585858;
}
</style>