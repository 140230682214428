<template>
    <div class="row mt-3">
        <div class="col-12 mb-3">
            <h4>D. RIWAYAT PEKERJAAN</h4>
        </div>
         <div class="col-12 mb-4">
            <button class="btn btn-primary" @click.prevent="addJob">Tambah Riwayat Pekerjaan</button>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>Nama Perusahaan</label>
                <div class="row">
                    <div class="col-12 mt-3" v-for="(job, i) in jobs" :key="i">
                        <input type="text" class="form-control" v-model="jobs[i].name">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>Jabatan dan Uraian Singkat Tugas-Tugas</label>
                <div class="row">
                    <div class="col-12 mt-3" v-for="(job, i) in jobs" :key="i">
                        <input type="text" class="form-control" v-model="jobs[i].jobDesc">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>Masa Kerja</label>
                <div class="row">
                    <div class="col-12 mt-3" v-for="(job, i) in jobs" :key="i">
                        <div class="row">
                            <div class="col-6">
                                <input type="text" class="form-control" v-model="jobs[i].yearIn" placeholder="Mulai">
                            </div>
                            <div class="col-6">
                                <input type="text" class="form-control" v-model="jobs[i].yearOut" placeholder="Akhir">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            jobs: [{
                name: null,
                jobDesc: null,
                yearIn: null,
                yearOut: null
            }]
        }
    },
    methods: {
        addJob() {
            this.jobs.push({
                name: null,
                jobDesc: null,
                yearIn: null,
                yearOut: null
            });
        },
        resetData() {
             this.jobs = [{
                name: null,
                jobDesc: null,
                yearIn: null,
                yearOut: null
            }];
        }
    }
}
</script>