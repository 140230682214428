<template>
    <div class="row">
        <div class="col-12 mb-3">
            <h4>B. RIWAYAT PENDIDIKAN FORMAL <small class="text-danger">(*) wajib diisi</small></h4>
        </div>
        <div class="col-12 mb-4">
            <button class="btn btn-primary" @click.prevent="addEducation">Tambah Tingkat Pendidikan</button>
        </div>
        <div class="col-md-2">
            <div class="form-group">
                <label>Tingkat Pendidikan <small class="text-danger">*</small></label>
                <div class="row">
                    <div class="col-12 mt-3" v-for="(education, i) in educations" :key="i">
                        <input type="text" class="form-control" v-model="educations[i].level" :class="[{'is-invalid': errorFor(`education.educations.${i}.level`)}]">
                        <small class="text-danger" v-if="errorFor(`education.educations.${i}.level`)">{{ errorFor(`education.educations.${i}.level`) }}</small>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label>Nama Institusi Pendidikan <small class="text-danger">*</small></label>
                <div class="row">
                    <div class="col-12 mt-3" v-for="(education, i) in educations" :key="i">
                        <input type="text" class="form-control" v-model="educations[i].name" :class="[{'is-invalid': errorFor(`education.educations.${i}.name`)}]">
                        <small class="text-danger" v-if="errorFor(`education.educations.${i}.name`)">{{ errorFor(`education.educations.${i}.name`) }}</small>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <div class="form-group">
                <label>Jurusan/Bidang Studi <small class="text-danger">*</small></label>
                <div class="row">
                    <div class="col-12 mt-3" v-for="(education, i) in educations" :key="i">
                        <input type="text" class="form-control" v-model="educations[i].speciality" :class="[{'is-invalid': errorFor(`education.educations.${i}.speciality`)}]">
                        <small class="text-danger" v-if="errorFor(`education.educations.${i}.speciality`)">{{ errorFor(`education.educations.${i}.speciality`) }}</small>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <div class="form-group">
                <label>Tahun Pelajaran <small class="text-danger">*</small></label>
                <div class="row">
                    <div class="col-12 mt-3" v-for="(education, i) in educations" :key="i">
                        <div class="row">
                            <div class="col-6">
                                <input type="number" class="form-control" v-model="educations[i].year_in" :class="[{'is-invalid': errorFor(`education.educations.${i}.year_in`)}]">
                                <small class="text-danger" v-if="errorFor(`education.educations.${i}.year_in`)">{{ errorFor(`education.educations.${i}.year_in`) }}</small>
                            </div>
                            <div class="col-1">
                                s/d
                            </div>
                            <div class="col-5">
                                <input type="number" class="form-control" v-model="educations[i].year_out" :class="[{'is-invalid': errorFor(`education.educations.${i}.year_out`)}]">
                                <small class="text-danger" v-if="errorFor(`education.educations.${i}.year_out`)">{{ errorFor(`education.educations.${i}.year_out`) }}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            educations: [{
                level: null,
                name: null,
                speciality: null,
                year_in: null,
                year_out: null
            }]
        }
    },
    props: ['errors'],
    methods: {
        addEducation() {
            this.educations.push({
                level: null,
                name: null,
                speciality: null,
                year_in: null,
                year_out: null
            })
        },
        errorFor(field) {
            if(this.errors != null) {
                if(typeof this.errors[field] == "object") {
                    return this.errors[field][0];
                }
            }
            return null;
        },
        resetData() {
             this.educations = [{
                level: null,
                name: null,
                speciality: null,
                year_in: null,
                year_out: null
            }];
        }
    }
}
</script>