import Vue from 'vue'
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import storeDefiniton from './store';
import App from './App.vue'
import router from './routes';
import ValidationErrors from './shared/ValidationErrors.vue';

Vue.config.productionTip = false
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.component("v-errors", ValidationErrors);

const store = new Vuex.Store(storeDefiniton);

new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate() {
    this.$store.dispatch('loadJob');
  }
}).$mount('#app')
