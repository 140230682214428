<template>
    <div class="error-box">
        <h1>SUKSES!</h1>
        <h3>Data diri anda berhasil dikirim.</h3>
        <p>Kami akan menghubungi anda jika data anda memenuhi kriteria.</p>
        <a href="/" class="btn btn-custom">Kembali Ke Halaman Utama</a>
    </div>
</template>

<script>
export default {
    mounted() {
         this.$store.dispatch('removeJobId');
    }
}
</script>

<style scoped>
h1 {
    font-size: 8em;
}

@media screen and (max-width: 780px) {
    h1 {
        font-size: 4em;   
    }
}
</style>