<template>
    <div class="container mt-5 pb-5">
        <form @submit.prevent="checkCaptchaBeforeSubmit">
            <Personal ref="personal" v-bind:errors="errors" />
            <Education ref="education" v-bind:errors="errors" />
            <Course ref="course" />
            <Jobs ref="jobs" />
            <Language ref="language" />
            <Deseases ref="deseases" />
            <Others ref="others" v-bind:errors="errors" />
            <References ref="references" v-bind:errors="errors" />
            <Statement ref="statement" v-bind:errors="errors" />
            <Information ref="information" />
            <Documents ref="documents" v-bind:errors="errors" />
            <div class="row">
                <div class="col-12">
                    <vue-recaptcha ref="recaptcha" @verify="onVerify" @expired="onExpired" sitekey="6Le9EE8aAAAAAEmJWMDQpZPGdZ5lHqBx0L7rhTkr"></vue-recaptcha>
                </div>
                <div class="col-12" v-if="errorCaptcha">
                    <small class="text-danger">Captcha wajib dichecklist</small>
                </div>
                <div class="col-12 mt-3">
                    <p class="text-danger" v-if="errors">Ada data wajib yang belum diisi. Silahkan dicek kembali.</p>
                    <button class="btn btn-primary" type="submit" v-if="!loading">Submit Data</button>
                    <button class="btn btn-primary" v-else disabled>Submitting..</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';
import VueRecaptcha from 'vue-recaptcha';
import Course from './form/Course';
import Education from './form/Education';
import Jobs from './form/Jobs';
import Personal from './form/Personal';
import Language from './form/Language';
import Deseases from './form/Deseases';
import Others from './form/Others';
import References from './form/References';
import Statement from './form/Statement';
import Information from './form/Information';
import Documents from './form/Documents';

export default {
    mounted() {
        this.onExpired();
        if(!this.job_id) {
            this.$router.push({ path: '/' });
        }
    },
    data() {
        return {
            loading: false,
            recaptchaToken: null,
            errors: null,
            errorCaptcha: false
        }
    },
    components: {
        Personal,
        Education,
        Course,
        Jobs,
        Language,
        Deseases,
        Others,
        References,
        Statement,
        Information,
        Documents,
        'vue-recaptcha': VueRecaptcha
    },
    computed: {
        ...mapState({
            job_id: state => state.job.id,
            apiUrl: "apiUrl"
        })
    },
    methods: {
        checkCaptchaBeforeSubmit() {
            this.errorCaptcha = false;
            if(this.recaptchaToken) {
                this.submitForm();
            }
        },
        async submitForm() {
            this.loading = true;
            this.errors = null;

            let formData = new FormData();
            formData.append('jobId', this.job_id);
            formData.append('personal', JSON.stringify(this.$refs.personal._data));
            formData.append('education', JSON.stringify(this.$refs.education._data));
            formData.append('course', JSON.stringify(this.$refs.course._data));
            formData.append('jobs', JSON.stringify(this.$refs.jobs._data));
            formData.append('language', JSON.stringify(this.$refs.language._data));
            formData.append('deseases', JSON.stringify(this.$refs.deseases._data));
            formData.append('others', JSON.stringify(this.$refs.others._data));
            formData.append('references', JSON.stringify(this.$refs.references._data));
            formData.append('statement', JSON.stringify(this.$refs.statement._data));
            formData.append('information', JSON.stringify(this.$refs.information._data));
            formData.append('ijazah', this.$refs.documents._data.ijazah);
            formData.append('cv', this.$refs.documents._data.cv);
            formData.append('ktp', this.$refs.documents._data.ktp);
            formData.append('kk', this.$refs.documents._data.kk);
            formData.append('sim', this.$refs.documents._data.sim);
            formData.append('suratnikah', this.$refs.documents._data.suratNikah);
            formData.append('aktakelahiran', this.$refs.documents._data.aktaKelahiran);
            formData.append('sertifikat', this.$refs.documents._data.sertifikat);
            formData.append('recaptchaToken', this.recaptchaToken); 

            try {
                await axios.post(`${this.apiUrl}/api/applicants/store`, 
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );

                window.location.href = '/success';

                this.loading = false;
                this.onExpired();
                this.recaptchaToken = null;
            } catch(error) {
                this.errors = error.response && error.response.data.errors;
                this.loading = false;
            }

            this.onExpired();
            this.recaptchaToken = null;
        },
        onVerify(recaptchaToken) {
            this.recaptchaToken = recaptchaToken;
            if(recaptchaToken) {
                this.errorCaptcha = true;
            }
        },
        onExpired() {
            this.$refs.recaptcha.reset();
        }
    }
}
</script>