<template>
    <div class="content container-fluid">
    
        <!-- Page Header -->
        <div class="page-header">
            <div class="row align-items-center">
                <div class="col text-center">
                    <h4 class="page-title">Ingin gabung menjadi keluarga besar Bintoro Corp ?</h4>
                </div>
            </div>
        </div>
        <!-- /Page Header -->
        
        <div class="row staff-grid-row">
            <ListJob 
                v-for="j in jobs"
                :key="j.id"
                :id="j.id"
                :name="j.name"
                :jobtype="j.job_type"
                :logo="j.logo"
                @jobdetail="jobDetail($event)"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';
import ListJob from './partials/ListJob';

export default {
    components: { ListJob },
    computed: {
        ...mapState({ apiUrl: "apiUrl" })
    },
    data:() => ({
        jobs: null
    }),
    created() {
        this.loadJobs();
    },
    methods: {
        async loadJobs() {
            try {
                const job = await axios.get(`${this.apiUrl}/api/jobs/open`);
                this.jobs = job.data;
            }  catch(error) {
                console.log(error)
            }
        },
        jobDetail(id) {
           this.$router.push({ name: 'job', params: { id: id } });
        }
    },
}
</script>

<style>
.page-title {
    color: #1f1f1f;
    font-size: 21px !important;
    font-weight: 500;
    margin: 15px !important;
}
</style>