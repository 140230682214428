<template>
    <div class="row mt-3">
        <div class="col-12 mb-3">
            <h4>E. KEMAMPUAN BAHASA ASING</h4>
            <p>Keterangan: Pilihlah tingkat kemampuan anda kategori di bawah ini.</p>
            <div class="row">
                <div class="col-md-4">
                    <p>A = Active, Baik Sekali dan Lancar</p>
                    <p>C = Dibawah Standard Rata-Rata</p>
                </div>
                <div class="col-md-4">
                    <p>B = Baik, Kemampuan Rata-Rata</p>
                    <p>D = Tidak Bisa Sama Sekali</p>
                </div>
            </div>
        </div>
        <div class="col-12 mb-4">
            <button class="btn btn-primary" @click.prevent="addLanguage">Tambah Bahasa Asing</button>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label>Bahasa Asing</label>
                <div class="row">
                    <div class="col-12 mt-3" v-for="(language, i) in languages" :key="i">
                        <input type="text" class="form-control" v-model="languages[i].name">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label>Membaca</label>
                <div class="row">
                    <div class="col-12 mt-3" v-for="(language, i) in languages" :key="i">
                        <select class="form-control" v-model="languages[i].reading">
                            <option :value="null" selected disabled>--Pilihan--</option>
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                            <option value="D">D</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <div class="form-group">
                <label>Menulis</label>
                <div class="row">
                    <div class="col-12 mt-3" v-for="(language, i) in languages" :key="i">
                        <select class="form-control" v-model="languages[i].writing">
                            <option :value="null" selected disabled>--Pilihan--</option>
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                            <option value="D">D</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <div class="form-group">
                <label>Berbicara</label>
                <div class="row">
                    <div class="col-12 mt-3" v-for="(language, i) in languages" :key="i">
                        <select class="form-control" v-model="languages[i].speaking">
                            <option :value="null" selected disabled>--Pilihan--</option>
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                            <option value="D">D</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <div class="form-group">
                <label>Mengerti</label>
                <div class="row">
                    <div class="col-12 mt-3" v-for="(language, i) in languages" :key="i">
                        <select class="form-control" v-model="languages[i].understanding">
                            <option :value="null" selected disabled>--Pilihan--</option>
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                            <option value="D">D</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            languages: [{
                name: null,
                reading: null,
                writing: null,
                speaking: null,
                understanding: null
            }]
        }
    },
    methods: {
        addLanguage() {
            this.languages.push({
                name: null,
                reading: null,
                writing: null,
                speaking: null,
                understanding: null
            });
        },
        resetData() {
            this.languages = [{
                name: null,
                reading: null,
                writing: null,
                speaking: null,
                understanding: null
            }];
        }
    }
}
</script>