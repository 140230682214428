export default {
    state: {
        apiUrl: 'https://hr.bintorocorp.co.id',
        job: {}
    },
    mutations: {
        setJob(state, payload) {
            state.job = payload;
        }
    },
    actions: {
        setJob({ commit, state }, payload) {
            commit('setJob', payload);
            localStorage.setItem('job', JSON.stringify(state.job));
        },
        removeJobId({ commit }) {
            commit('setJob', {});
            localStorage.removeItem('job');
        },
        loadJob({ commit }) {
            const localJob = localStorage.getItem('job');
            if(localJob) {
                commit('setJob', JSON.parse(localJob));
            }
        }
    },
    getters: {

    }
}